// Default imports
import React, { useState, useRef, useEffect } from "react";

import WpImage from "../components/WpImage";
import SliderArrow from "../components/SliderArrow";

// Swiper
import SwiperCore, { Navigation, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const OurClientsSlider = ({ slides }) => {
  // Set refs
  const nextArrowRef = useRef(null);
  const sliderRef = useRef(null);

  // Run autoplay if slider is in viewport
  useEffect(() => {
    if (typeof window !== "undefined" && sliderRef.current.swiper) {
      window.addEventListener("scroll", checkSliderVisibility);
    }

    return () => {
      window.removeEventListener("scroll", checkSliderVisibility);
    };
  }, [checkSliderVisibility]);

  function checkSliderVisibility(e) {
    const sliderPosY = sliderRef.current.getBoundingClientRect().top;
    const sliderHeight = sliderRef.current.offsetHeight;

    if (sliderPosY < sliderHeight / 2 && sliderPosY + sliderHeight > 0) {
      sliderRef.current.swiper.autoplay.run();
    } else {
      sliderRef.current.swiper.autoplay.pause();
    }
  }

  return (
    <div className="our-clients__slider">
      <Swiper
        ref={sliderRef}
        // Swiper options
        spaceBetween={50}
        slidesPerView={1}
        speed={1200}
        freeMode
        freeModeSticky
        loop
        autoplay={{ disableOnInteraction: false, delay: 5000, waitForTransition: false}}
        // Events
        onInit={(swiper) => {
          swiper.params.navigation.nextEl = nextArrowRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          // when window width is >= 1320px
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
          1320: {
            slidesPerView: 2,
          },
          1820: {
            slidesPerView: 3,
          },
        }}
      >
        {slides.map((item) => (
          <SwiperSlide key={`${item.authorName}-${item.authorOffice}`}>
            <OurClientsItem key={`${item.authorName}-${item.authorOffice}`} {...item} />
          </SwiperSlide>
        ))}

        <SliderArrow ref={nextArrowRef} className="our-clients__arrow -next" />
      </Swiper>
    </div>
  );
};

const OurClientsItem = ({ authorName, authorOffice, text, logo }) => (
  <div className="our-clients__slide h-100">
    <div className="our-clients__slide-content">
      {logo && (
        <div>
          <WpImage className={`our-clients__logo`} image={logo} />
        </div>
      )}
      <p className="our-clients__comment">{text}</p>
      <strong className="our-clients__author">
        {authorName}
        <span className="our-clients__author-sep">|</span>
        {authorOffice}
      </strong>
    </div>
  </div>
);

export default OurClientsSlider;
